import React from "react";

import { Box, Card, Avatar, Rating, Typography } from "@mui/material";

const styles = {
  card: {
    m: 2,
    width: 300,
    height: 280,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  userInfo: {
    m: 1,
    p: 1,
    display: "flex",
    marginBottom: 0,
    alignItems: "center",
  },
  feedback: {
    marginTop: 2,
    marginLeft: 2,
    marginRight: 2,
    flex: 1,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  rating: {
    m: 1,
    p: 1,
    display: "flex",
  },
};

const FeedbackCard = ({ data, primaryColor }) => {
  const { name, feedback, rating } = data;

  return (
    <Card raised sx={styles.card}>
      <Box sx={styles.userInfo}>
        <Avatar sx={{ backgroundColor: primaryColor }}>{name[0]}</Avatar>

        <Typography sx={{ m: 1 }}>
          <b>{name}</b>
        </Typography>
      </Box>

      <Box sx={styles.feedback}>
        <Typography>
          {feedback?.length > 180
            ? feedback?.substring(0, 180 - 3) + "..."
            : feedback}
        </Typography>
      </Box>

      <Box sx={styles.rating}  >
        <Rating value={rating} readOnly />
      </Box>
    </Card>
  );
};

export default FeedbackCard;
