import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  Chip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { connect } from "react-redux";
import * as actions from "../../Redux/Actions/cartAction";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
function CartCard(props) {
  const data = props.data;

  return (
    <Card sx={{ mt: 2, mb: 2 }}>
      <CardContent>
        <div style={{ display: "flex" }}>
          <Typography fontSize={18}>
            <b>{data.name}</b>
          </Typography>
          <Chip
            label={data.isPack ? "Pack" : "Unit"}
            color={data.isPack ? "secondary" : "primary"}
            size="small"
            sx={{ ml: 1 }}
          />
        </div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography color={"primary"}>
            <b>Rs. {data.price}</b>
          </Typography>
          {data.qty ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                onClick={() => {
                  props.removeFromCart({
                    ...data,
                    qty: 1,
                  });
                }}
              >
                {data.quantity === 1 ? (
                  <DeleteOutlineOutlinedIcon />
                ) : (
                  <RemoveIcon />
                )}
              </Button>
              <Typography sx={{ ml: 1, mr: 1 }}>{data.qty}</Typography>
              <Button
                onClick={() => {
                  props.addItemToCart({
                    ...data,
                    qty: 1,
                  });
                }}
              >
                <AddIcon />
              </Button>
            </Box>
          ) : null}
        </Box>
      </CardContent>
    </Card>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    addItemToCart: (data) => dispatch(actions.addToCart(data)),
    removeFromCart: (data) => dispatch(actions.removeFromCart(data)),
  };
};

export default connect(null, mapDispatchToProps)(CartCard);
