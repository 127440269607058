import React, { useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Container,
  CssBaseline,
  Typography,
  Grid,
  Link,
  Box,
  Collapse,
  Alert,
  IconButton,
  Stack,
} from "@mui/material";

import API from "../../utils/API";

import { loginUser } from "../../Context/actions/Auth.actions";
import AuthGlobal from "../../Context/store/AuthGlobal";
import { LoadingButton } from "@mui/lab";
import {
  ChevronLeft,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

function Signin() {
  const navigate = useNavigate();
  const context = useContext(AuthGlobal);

  const [phone, setPhone] = useState();
  const [password, setPassword] = useState();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const onSignInPressed = async (event) => {
    setLoading(true);
    event.preventDefault();
    const user = {
      phone,
      password,
    };

    try {
      const res = await API({
        method: "POST",
        url: "api-token-auth/",
        requestConfig: {
          username: user.phone,
          password: user.password,
        },
      });
      loginUser(res.token, context.dispatch);
    } catch (error) {
      console.log(error);
      setOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{ display: "flex", height: "100vh", alignItems: "center" }}
    >
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",

          backgroundColor: "#fff",
          padding: 5,
          borderRadius: 10,
        }}
      >
        <KeyboardBackspaceIcon
          onClick={() => navigate("/")}
          color="primary"
          sx={{ mb: 2 }}
        />
        <Typography component="h1" variant="h5" fontWeight={"bold"}>
          Sign in to your account
        </Typography>

        <Typography variant="body2" sx={{ mt: 2 }}>
          Enter your phone number and password to sign in.
        </Typography>
        <Box
          component="form"
          onSubmit={onSignInPressed}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            fullWidth
            label="Phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <Box
            sx={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TextField
              label="Password"
              name="password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              margin="normal"
              required
              fullWidth
              onChange={(input) => {
                setPassword(input.target.value);
              }}
            />
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              sx={{ position: "absolute", right: 5, bottom: 15 }}
            >
              {showPassword ? (
                <VisibilityOutlined />
              ) : (
                <VisibilityOffOutlined />
              )}
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Link
              variant="body"
              fontSize={12}
              onClick={() => navigate("../ForgotPassword", { state: phone })}
            >
              Forgot password?
            </Link>
          </Box>
          <Collapse in={open}>
            <Alert
              onClose={() => setOpen(false)}
              severity="error"
              sx={{ width: "100%" }}
            >
              Wrong Password
            </Alert>
          </Collapse>
          <LoadingButton
            variant="contained"
            type="submit"
            fullWidth
            loading={loading}
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </LoadingButton>
          <Typography
            variant="body2"
            my={2}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            OR
          </Typography>
          <Button
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            onClick={() => navigate("../phone")}
          >
            Register with phone number
          </Button>
          <Button
            variant="outlined"
            fullWidth
            onClick={() => navigate("../email")}
          >
            Register with Email
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default Signin;
