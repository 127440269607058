import React, { useState, useEffect } from 'react'

//Material UI
import { Box, Container } from '@mui/material'

//fetch
import baseURL from '../../Assets/common/baseURL'
import axios from 'axios'
import LabCard from '../../Components/LabTests/LabCard'
import AboutHeader from '../../Components/AboutHeader'

function Labs() {

    const [labs, setLabs] = useState([])

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        const labdata = await axios
            .get(`${baseURL}getLabs`)
            .then((res) => setLabs(res.data.data.labs))
    }

    return (
        <>
            <AboutHeader>Labs</AboutHeader>
            <Container sx={{ mt: 3, mb: 3, minHeight: '40vh' }} >
                <Box display={'flex'} >
                    {labs.map((item) => {
                        return <LabCard data={item} />
                    })}
                </Box>
            </Container>
        </>
    )
}

export default Labs