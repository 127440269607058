import React, { useState, useEffect, useReducer } from "react";
import { setCurrentUser } from "../actions/Auth.actions";

import jwt_decode from "jwt-decode";
import AuthReducer from "../reducers/Auth.reducer";
import AuthGlobal from "./AuthGlobal";
import API from "../../utils/API";

const Auth = (props) => {
  const [stateUser, dispatch] = useReducer(AuthReducer, {
    isAuthenticated: null,
    user: {},
  });
  const [showChild, setShowChild] = useState(false);

  const loadCustomer = async (token) => {
    const res = await API({
      method: "GET",
      url: "customer/",
      requireAuth: true,
    });
    dispatch(setCurrentUser(token, res[0]));
  };

  useEffect(() => {
    setShowChild(true)
    const token = localStorage.getItem("auth_data");
    if (token) {
      loadCustomer(token);
    }

    return () => setShowChild(false);
  }, []);

  if (!showChild) {
    return null;
  }

  return (
    <AuthGlobal.Provider
      value={{
        stateUser,
        dispatch,
      }}
    >
      {props.children}
    </AuthGlobal.Provider>
  );
};

export default Auth;
