import React, { useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Link,
  Rating,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import baseURL from "../Assets/common/baseURL";
import API from "../utils/API";

function Feedback(props) {
  const navigate = useNavigate();

  const [profile, setProfile] = useState({});
  const [feedback, setFeedback] = useState();
  const [rating, setRating] = useState(1);

  useEffect(() => {
    loadProfile();
  }, []);

  const loadProfile = async () => {
    const res = await API({
      method: "GET",
      url: "customer/",
      requireAuth: true,
    });
    setProfile(res[0]);
  };

  const handlePublish = () => {
    const data = {
      name: profile.name,
      rating: rating,
      feedback: feedback,
    };
    API({
      method: "POST",
      url: "feedback/",
      requireAuth: true,
      requestConfig: data,
    })
      .then((res) => {
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 5, mb: 5 }}>
      <Breadcrumbs>
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
        <Link underline="hover" color="inherit">
          Feedback
        </Link>
      </Breadcrumbs>
      <Typography variant="h4">Feedback</Typography>
      <Box>
        <Box marginTop={2}>
          <Typography>
            Name: <b>{profile ? profile.name : ""}</b>
          </Typography>
          {profile && profile.email && (
            <Typography>
              Email: <b>{profile ? profile.email : ""}</b>
            </Typography>
          )}
        </Box>
        <Stack direction={"row"} spacing={0} >
          <Typography component="legend">Rating: </Typography>
          <Rating
            value={rating}
            onChange={(event, value) => setRating(value)}
          />
        </Stack>
        <TextField
          fullWidth
          id="feedback"
          label="Your FeedBack"
          onChange={(event) => {
            setFeedback(event.target.value);
          }}
          multiline
          rows={4}
          margin="normal"
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="contained" onClick={handlePublish}>
            Publish
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default Feedback;
