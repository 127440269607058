import React from 'react'
import { useNavigate } from 'react-router-dom'

//Material UI
import { Box, Button, Stack, Typography } from '@mui/material'

//Image
import Logo from '../Assets/images/logo.svg'

function PageNotFound() {

  const navigate = useNavigate()

  return (
    <div style={{ minHeight: '100vh', display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center' }}>
      <Box component={'img'} src={Logo} height={100} sx={{ mb: 5 }} />
      <Stack spacing={2} >
        <Typography variant='h3' >Page Not Found</Typography>
        <Button variant='contained' onClick={() => navigate('/')} >Go To Home Page</Button>
      </Stack>
    </div>
  )
}

export default PageNotFound