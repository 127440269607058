import { Navigate } from "react-router-dom";

const LoggedIn = ({ isLoggedIn, children, location }) => {
  if (isLoggedIn === true) {
    return <Navigate to={location ? location.pathname : "/"} replace />;
  }
  return children;
};

export default LoggedIn;
