import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

//Material UI
import { Box, Container, Stack, TextField, Button } from "@mui/material";

//Fetch
import API from "../../utils/API";
import AuthGlobal from "../../Context/store/AuthGlobal";
import { LoadingButton } from "@mui/lab";

function AccountDetails(props) {
  const navigate = useNavigate();

  const context = useContext(AuthGlobal);

  const [loading, setLoading] = useState(false);
  const [fullname, setFullname] = useState(
    props.userProfile ? props.userProfile.name : ""
  );
  const [email] = useState(props.userProfile ? props.userProfile.email : "");
  const [phone] = useState(props.userProfile ? props.userProfile.phone : "");
  const [address, setAddress] = useState(
    props.userProfile ? props.userProfile.address : ""
  );

  const name = props.userProfile ? props.userProfile.name : "";

  const UpdateAddress = async () => {
    setLoading(true);
    try {
      const updateAddress = {
        id: props.userProfile.id,
        name,
        address,
      };

      const updateRes = await API({
        method: "PUT",
        url: `customer/${context.stateUser.user.id}/`,
        requestConfig: {
          name: fullname,
          email,
          phone,
        },
      });
      alert("Details updated successfully.");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Box sx={{ mt: 2 }}>
        <Stack spacing={2}>
          <TextField
            label="Name"
            value={fullname}
            onChange={(e) => setFullname(e.target.value)}
          />
          <TextField label="Email" value={email} disabled />
          <TextField label="Phone" value={phone} disabled />
          <TextField
            label="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <Box display={"flex"} justifyContent={"flex-end"}>
            <LoadingButton loading={loading} variant={"contained"} onClick={UpdateAddress} sx={{ m: 2 }}>
              Save
            </LoadingButton>
          </Box>
        </Stack>
      </Box>
    </Container>
  );
}

export default AccountDetails;
