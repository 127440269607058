import React, { useState, useEffect } from 'react'
import { Button, Card, CardContent, Chip, Collapse, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

function TrackOrderCard(props) {

    const [open, setOpen] = useState(false)
    const [label, setLabel] = useState()
    const [color, setColor] = useState()

    const data = props.data;
    const items = data?.items;

    useEffect(() => {
        if (data.delivery_status === 'Pending') {
            setLabel('Pending')
            setColor('warning')
        } else if (data.delivery_status === 'Delivered') {
            setLabel('Delievered')
            setColor('success')
        } else if (data.delivery_status === 'Cancelled') {
            setLabel('Cancelled')
            setColor('error')
        }
    }, [])


    return (
        <Grid item xs={12}>
            <Card sx={{ m: 2 }}>
                <CardContent>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                        <Typography><b>Order # {data.invoice_no}</b></Typography>
                        <Chip label={label} color={color} />
                    </div>
                    <Typography>Total Amount : <b>{data.total_amount}</b></Typography>
                    <Typography>Items Count : <b>{items?.length}</b></Typography>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                        <Typography><b>Detail</b></Typography>
                        {open ? <Button variant='outlined' size='small' onClick={() => setOpen(false)} >Close</Button> : <Button size='small' variant='contained' onClick={() => setOpen(true)} >View Details</Button>}
                    </div>
                    <Collapse in={open}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>Product Name</b></TableCell>
                                    <TableCell><b>Quantity</b></TableCell>
                                    <TableCell><b>Amount</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items?.map((item) => {
                                    return (
                                        <TableRow>
                                            <TableCell>{item.product_name}</TableCell>
                                            <TableCell>{item.quantity}</TableCell>
                                            <TableCell>{item.amount}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Collapse>
                </CardContent>
            </Card>
        </Grid>
    )
}

/*
<Grid container >
                            {items.map((item) => {
                                return (
                                    <Grid item xs={6} >
                                        <Card sx={{ m: 2 }}>
                                            <CardContent>
                                                <Typography>{item.product_name}</Typography>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                                                    <Typography>{item.quantity}</Typography>
                                                    <Typography>{item.qty_type}</Typography>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                )
                            })}
                        </Grid>
                        */

export default TrackOrderCard