import React from 'react'
import { useNavigate } from 'react-router-dom'

// MAterail UI
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material'

function DoctorCard({ data, locations, locationSchedules, timeSlots }) {

    const navigate = useNavigate()

    return (
        <Grid item xs={10} md={4} >
            <Card>
                <CardContent sx={{ height: 150, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} >
                    <Box>
                        <Typography variant={'h6'} fontWeight={'bold'} >{data.name}</Typography>
                        <Typography fontSize={14} fontWeight={'bold'} >{data.qualification}</Typography>
                        <Typography fontSize={14} fontWeight={'bold'} >Experience: {data.experience}</Typography>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} >
                        <Typography ><b>Fee:</b> Rs. {data.fee}</Typography>
                        <Button
                            onClick={() => navigate('./doctor-appointment', { state: { data, locations, locationSchedules, timeSlots } })}
                        >Book Appointment</Button>
                    </Box>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default DoctorCard