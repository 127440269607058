import BrandCard from "./BrandCard";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { Button, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";

export function LeftArrow() {
  const {
    scrollPrev,
    initComplete,
    isFirstItemVisible,
    visibleItemsWithoutSeparators,
  } = useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );

  useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

  return (
    <Button disabled={disabled} onClick={() => scrollPrev()}>
      <ArrowBackIosIcon fontSize="small" />
    </Button>
  );
}

export function RightArrow() {
  const { isLastItemVisible, scrollNext, visibleItemsWithoutSeparators } =
    useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(
    !visibleItemsWithoutSeparators.length && isLastItemVisible
  );

  useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleItemsWithoutSeparators]);

  return (
    <Button disabled={disabled} onClick={() => scrollNext()}>
      <ArrowForwardIosIcon fontSize="small" />
    </Button>
  );
}

const Arrows = (props) => (
  <div style={{ display: "flex", justifyContent: "space-between" }}>
    <Typography variant="h6" marginTop={1} marginBottom={1}>
      <b>{props.heading}</b>
    </Typography>

    <div style={{ display: "flex" }}>
      <LeftArrow /> <RightArrow />
    </div>
  </div>
);

function BrandContainer(props) {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;

  return (
    <ScrollMenu Header={() => Arrows(props)}>
      {props.brands?.map((data, index) => (
        <BrandCard key={index} data={data} primaryColor={primaryColor} />
      ))}
    </ScrollMenu>
  );
}

export default BrandContainer;
