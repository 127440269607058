import React, { useContext } from 'react'
import { Button, Typography } from '@mui/material';

import { useNavigate } from 'react-router-dom'

import { logoutUser } from "../../Context/actions/Auth.actions";
import AuthGlobal from '../../Context/store/AuthGlobal';

function Logout() {

    const context = useContext(AuthGlobal)

    const navigate = useNavigate()

    return (
        <div style={{ margin: 4, minHeight: '30vh' }} >
            <Typography variant='h5' margin={2} >are you sure want to logout?</Typography>
            <Button variant={'contained'} onClick={() => {
                logoutUser(context.dispatch)
                navigate('/')
            }}>Logout</Button>
        </div>
    )
}

export default Logout