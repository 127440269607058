import axios from "axios";

import baseURL from "../Assets/common/baseURL";


export default axios.create({
    baseURL,
    headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json',
    }
});