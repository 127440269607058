import React, { useState, useEffect, useContext } from "react";

import axios from "axios";
import baseURL from "../../Assets/common/baseURL";
import AuthGlobal from "../../Context/store/AuthGlobal";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";

import API from "../../utils/API";

export default function Address(props) {
  const context = useContext(AuthGlobal);

  const [userProfile, setUserProfile] = useState([]);

  const [detectChange, setDetectChange] = useState(false);
  const [name, setName] = useState();
  const [address, setAddress] = useState();

  useEffect(() => {
    if (userProfile.address !== address) {
      setDetectChange(true);
      props.setUserAddress(address);
    }
    if (userProfile.name !== name) {
      setDetectChange(true);
      props.setCustomerName(name);
    }
  }, [name, address]);

  useEffect(() => {
    setUserProfile(context.stateUser.user);
    setName(context.stateUser.user.name);
    setAddress(context.stateUser.user.address);
    props.setUser_ID(context.stateUser.user.id);
    props.setCustomerName(context.stateUser.user.name);
    props.setPhone(context.stateUser.user.phone);
    props.setEmail(context.stateUser.user.email);
    props.setUserAddress(context.stateUser.user.address);
  }, []);

  const UpdateAddress = () => {
    try {
      const res = API({
        method: "PUT",
        url: `customer/${context.stateUser.user.id}/`,
        requireAuth: true,
        requestConfig: {
          name,
          address,
        },
      });
      setDetectChange(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Shipping address
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="standard"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="standard"
            value={userProfile ? userProfile.phone : ""}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="address"
            name="address"
            placeholder="Address"
            fullWidth
            value={address}
            autoComplete="shipping address-line1"
            variant="standard"
            onChange={(e) => setAddress(e.target.value)}
          />
        </Grid>
        {detectChange ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              width: "100%",
            }}
          >
            <Button variant={"outlined"} onClick={UpdateAddress} sx={{ m: 2 }}>
              Save
            </Button>
          </div>
        ) : null}
      </Grid>
    </React.Fragment>
  );
}
