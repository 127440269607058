import React from "react";
import { useLocation } from "react-router-dom";

// Material UI
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";

// Images
import AboutHeader from "../../Components/AboutHeader";

function TeamMember() {
  const teamMember = useLocation().state.item;

  return (
    <>
      <AboutHeader>{teamMember.name}</AboutHeader>
      <Container maxWidth={"lg"} sx={{ mt: 5, mb: 5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Card>
              <CardMedia
                component={"img"}
                src={teamMember.image}
                height={320}
                sx={{ objectPosition: "top" }}
              />
              <CardContent>
                <Typography variant={"h6"} fontWeight={"bold"}>
                  {teamMember.designation}
                </Typography>
                <Divider sx={{ mt: 1, mb: 1 }} />
                <Stack direction={'row'} spacing={2} alignItems={"center"}>
                  <EmailIcon color={"primary"} fontSize={"small"} />
                  <Typography> {teamMember.email}</Typography>
                </Stack>
                <Stack direction={'row'} spacing={2} alignItems={"center"}>
                  <PhoneIcon color={"primary"} fontSize={"small"} />
                  <Typography> {teamMember.phone_number}</Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box mt={2} >
              <Typography>{teamMember.dsescription}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default TeamMember;
