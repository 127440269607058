import baseURL from "../Assets/common/baseURL";
import AboutHeader from "../Components/AboutHeader";

import React, { useState } from "react";
import { Circle } from "@mui/icons-material";
import {
  Box,
  Grid,
  List,
  Modal,
  Stack,
  Alert,
  Button,
  ListItem,
  Snackbar,
  Container,
  TextField,
  Typography,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import API from "../utils/API";
import moment from "moment";

function Franchise() {
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackBar] = useState(false);

  const upload = new FormData();

  const submitApplication = async (event) => {
    event.preventDefault();
    setLoading(true);

    const data = new FormData(event.currentTarget);

    if (data.get("phone").length !== 11) {
      alert("Phone number must be 11 digits.");
      setLoading(false);
      return;
    }

    if (data.get("phone")[0] !== "0") {
      alert("Phone number must start with 0.");
      setLoading(false);
      return;
    }

    try {
      const res = await API({
        method: "POST",
        url: "franchise/",
        requestConfig: {
          first_name: data.get("first-name"),
          last_name: data.get("last-name"),
          email: data.get("email"),
          phone: data.get("phone"),
          investment: data.get("amount"),
          timing: data.get("operation"),
          location: data.get("places"),
          city: data.get("city"),
          details: data.get("details"),
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setSnackBar(true);
    }
  };

  return (
    <>
      <AboutHeader>Franchise Opportunity</AboutHeader>
      <Container sx={{ mt: 5, mb: 5 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Box
              component={"form"}
              id="franchise-form"
              onSubmit={submitApplication}
            >
              <Stack spacing={3}>
                <Stack direction={"row"} spacing={2}>
                  <TextField
                    id={"first-name"}
                    name={"first-name"}
                    size="small"
                    fullWidth
                    label={"First Name"}
                  />
                  <TextField
                    id={"last-name"}
                    name={"last-name"}
                    size="small"
                    fullWidth
                    label={"Last Name"}
                  />
                </Stack>
                <Stack direction={"row"} spacing={2}>
                  <TextField
                    id={"email"}
                    name={"email"}
                    size="small"
                    fullWidth
                    required
                    label={"Email"}
                  />
                  <TextField
                    id={"phone"}
                    name={"phone"}
                    size="small"
                    fullWidth
                    required
                    label={"Phone"}
                  />
                </Stack>
                <Stack direction={"row"} spacing={2}>
                  <TextField
                    id={"amount"}
                    name={"amount"}
                    size="small"
                    fullWidth
                    label={"Amount you want to Invest"}
                  />
                </Stack>
                <Stack direction={"row"} spacing={2}>
                  <TextField
                    id={"places"}
                    name={"places"}
                    size="small"
                    fullWidth
                    label={"Enter Place Details"}
                  />
                  <TextField
                    id={"operation"}
                    name={"operation"}
                    defaultValue={moment().format("YYYY-MM-DD")}
                    type="date"
                    size="small"
                    fullWidth
                    label={"Enter Operation Timing"}
                  />
                </Stack>
                <Stack spacing={2}>
                  <TextField
                    id={"city"}
                    name={"city"}
                    size="small"
                    fullWidth
                    label={"City"}
                  />
                  <TextField
                    id={"details"}
                    name={"details"}
                    size="small"
                    fullWidth
                    label={"Details"}
                  />
                </Stack>
              </Stack>
              <Box display={"flex"} justifyContent={"flex-end"} margin={3}>
                <Button type={"submit"} variant={"contained"} size={"large"}>
                  Submit
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h4">Franchise Benefit’s</Typography>
            <List dense={2}>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Right to use our Trade Mark & Logo" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Site Evaluation" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Marketing Support" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Purchase Order’s Assistance as per Area Demand" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Staff Training’s Session’s" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Regulatary & Licensing Assistance" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Availability of Dispensing products" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Online Pharmacy Support" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Home Delivery Order’s Through our App & Website" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Store Renovation Plan" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Vendors List for Renovation & interior" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Sale’s Target’s Planing" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Sale’s Working through Marketing Team" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Over All Consultancy on operational Matter’s" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Help in Staff Hiring" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Internal Audit Training’s" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Inventory Managment Training’s" />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Container>
      <Modal
        open={loading}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <CircularProgress />
      </Modal>
      <Snackbar
        open={snackbar}
        autoHideDuration={6000}
        onClose={() => setSnackBar(false)}
      >
        <Alert severity="success" variant="filled" sx={{ width: "100%" }}>
          Your submission has been received.
        </Alert>
      </Snackbar>
    </>
  );
}

export default Franchise;
