import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  Link as CustomLink,
} from "@mui/material";

import baseURL from "../Assets/common/baseURL";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import CallRoundedIcon from "@mui/icons-material/CallRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import AlternateEmailRoundedIcon from "@mui/icons-material/AlternateEmailRounded";
import API from "../utils/API";
import { LinkedIn } from "@mui/icons-material";

function Footer() {
  const [data, setData] = useState([]);
  const [pages, setPages] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const res = await API({
      method: "GET",
      url: "company_profile/",
    });
    setData(res[0]);
    const res1 = await API({
      method: "GET",
      url: "page/",
    });
    setPages(res1);
  };

  const rawHTML = `<span>${data ? data.footer : ""}<span>`;

  function containsPageWithWeb(pages, searchName) {
    return pages.some(
      (page) => page.name.includes(searchName) && page.web === true
    );
  }

  return (
    <Container component="main" maxWidth sx={{ backgroundColor: "#222529" }}>
      <Box sx={{ pt: 5, pb: 1 }}>
        <Grid container>
          <Grid
            item
            xs={6}
            md={3}
            rowGap={1}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Typography color={"#fff"} margin={1} variant="h5">
              Links
            </Typography>
            <Link to={"/branches"}>
              <CustomLink underline={"hover"} color={"#fff"}>
                Our Branches
              </CustomLink>
            </Link>
            <Link to={"/fastorder"}>
              <CustomLink underline={"hover"} color={"#fff"}>
                Fast Order
              </CustomLink>
            </Link>
            <Link to={"/Feedback"}>
              <CustomLink underline={"hover"} color={"#fff"}>
                Feedback
              </CustomLink>
            </Link>
          </Grid>
          <Grid
            item
            xs={6}
            md={3}
            rowGap={1}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Typography color={"#fff"} margin={1} variant="h5">
              About
            </Typography>
            <Link to={"/franchise"}>
              <CustomLink underline={"hover"} color={"#fff"}>
                Franchise
              </CustomLink>
            </Link>
            <Link to={"/careers"}>
              <CustomLink underline={"hover"} color={"#fff"}>
                Careers
              </CustomLink>
            </Link>
            <Link
              to={"/our-team"}
              onClick={() => document.documentElement.scrollTo(0, 0)}
            >
              <CustomLink underline={"hover"} color={"#fff"}>
                Our Team
              </CustomLink>
            </Link>
            {containsPageWithWeb(pages, "Privacy Policy") && (
              <Link to={"/privacy-policy"}>
                <CustomLink underline={"hover"} color={"#fff"}>
                  Privacy Policy
                </CustomLink>
              </Link>
            )}
            <Link to={"/our-history"}>
              <CustomLink underline={"hover"} color={"#fff"}>
                Our History
              </CustomLink>
            </Link>
            {containsPageWithWeb(pages, "Security Policy") && (
              <Link to={"/security-policy"}>
                <CustomLink underline={"hover"} color={"#fff"}>
                  Security Policy
                </CustomLink>
              </Link>
            )}
            {containsPageWithWeb(pages, "Shipping & Returns") && (
              <Link to={"/shipping-and-returns"}>
              <CustomLink underline={"hover"} color={"#fff"}>
                Shipping {`&`} Returns
              </CustomLink>
            </Link>
            )}
          </Grid>
          <Grid item xs={6} md={3} style={{ textJustify: "center" }}>
            <Typography color={"#fff"} margin={1} variant="h5">
              Contact us
            </Typography>
            <Typography color={"#fff"}>
              <CallRoundedIcon /> {data.phone_number}
            </Typography>
            <Typography color={"#fff"}>
              <AlternateEmailRoundedIcon /> {data.email}
            </Typography>
            <Typography color={"#fff"}>
              <LocationOnRoundedIcon /> {data.address}
            </Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <div>
              <a href="https://play.google.com/store/apps/details?id=com.nmppharmacy&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img
                  alt="Get it on Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  height={"80%"}
                  width={"80%"}
                />
              </a>
            </div>
            <div>
              <a href="https://apps.apple.com/us/app/nmp-pharmacy/id6560116676?itscg=30200&itsct=apps_box_badge&mttnsubad=6560116676">
                <img
                  src="https://toolbox.marketingtools.apple.com/api/v2/badges/download-on-the-app-store/black/en-us?releaseDate=1721952000"
                  alt="Download on the App Store"
                  style={{
                    marginLeft: 20,
                  }}
                  height={"69%"}
                  width={"69%"}
                />
              </a>
            </div>

            <div
              style={{ display: "flex", alignItems: "center", marginLeft: 20 }}
            >
              {data.facebook_link && (
                <a href={data.facebook_link} target="_blank">
                  <FacebookIcon color="text" fontSize="large" sx={{ m: 1 }} />
                </a>
              )}
              {data.instagram_link && (
                <a href={data.instagram_link} target="_blank">
                  <InstagramIcon color="text" fontSize="large" sx={{ m: 1 }} />
                </a>
              )}
              {data.x_link && (
                <a href={data.x_link} target="_blank">
                  <TwitterIcon color="text" fontSize="large" sx={{ m: 1 }} />
                </a>
              )}
              {data.linkedin_link && (
                <a href={data.linkedin_link} target="_blank">
                  <LinkedIn color="text" fontSize="large" sx={{ m: 1 }} />
                </a>
              )}
            </div>
          </Grid>
        </Grid>
        <Divider light={true} sx={{ mt: 2, mb: 2 }} />
        <Box display={"flex"} justifyContent={"center"}>
          <Typography
            color={"#fff"}
            fontSize={14}
            dangerouslySetInnerHTML={{ __html: rawHTML }}
            sx={{
              ":link": {
                color: "#fff",
                textDecoration: "none",
              },
            }}
          ></Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default Footer;
