import "./styles.css";
import React, { useContext, useEffect } from "react";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

import { Link } from "react-router-dom";
import { AppBar, useTheme, useMediaQuery, Typography } from "@mui/material";
import AuthGlobal from "../../Context/store/AuthGlobal";
import API from "../../utils/API";

function Header(props) {
  const theme = useTheme();

  const context = useContext(AuthGlobal);

  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const [data, setData] = React.useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const res = await API({
      method: "GET",
      url: "company_profile/",
    });
    setData(res[0]);
  };

  return (
    <AppBar position="static">
      <div className="container">
        <div className="sub-container">
          <Link to={"/"}>
            <Typography sx={{ fontSize: "1rem" }} className="header-left">
              <PersonOutlineIcon fontSize="small" /> Welcome to {data.name}
            </Typography>
          </Link>
          {isMatch ? null : (
            <div className="header-right">
              <div className="header-menu">
                <Link to={"track-order"}>
                  <Typography
                    sx={{ fontSize: "0.8rem" }}
                    className="header-menu-item"
                  >
                    Track Order
                  </Typography>
                </Link>
                <Link to={"/Feedback"}>
                  <Typography
                    sx={{ fontSize: "0.8rem" }}
                    className="header-menu-item"
                  >
                    Feedback
                  </Typography>
                </Link>
                <Link to={"/branches"}>
                  <Typography
                    sx={{ fontSize: "0.8rem" }}
                    className="header-menu-item"
                  >
                    Our Branches
                  </Typography>
                </Link>
                {props.authStatus ? (
                  <Link to={"./profile"}>
                    <Typography
                      sx={{ fontSize: "0.8rem" }}
                      className="header-menu-item"
                    >
                      Hello, {context.stateUser?.user?.name}
                    </Typography>
                  </Link>
                ) : (
                  <Link to={"/account/signin"}>
                    <Typography
                      sx={{ fontSize: "0.8rem" }}
                      className="header-menu-item"
                    >
                      Login
                    </Typography>
                  </Link>
                )}
              </div>
              <div className="social-icons"></div>
            </div>
          )}
        </div>
      </div>
    </AppBar>
  );
}

export default Header;
