import { ADD_TO_CART, REMOVE_FROM_CART, CLEAR_CART } from '../constants';

const initialState = {
  Items: [],
  totalPrice: 0,
};

const cartItems = (state = initialState, action) => {
  switch (action.type) {

    case ADD_TO_CART:
      const item = state.Items.find(
        item =>
          item.id === action.payload.id &&
          item.isPack === action.payload.isPack,
      );
      const totalItemPrice = action.payload.price * action.payload.qty;
      if (item) {
        return {
          ...state,
          Items: state.Items.map(item =>
            item.id === action.payload.id &&
              item.isPack === action.payload.isPack
              ? {
                ...item,
                qty:
                  item.qty +
                  action.payload.qty,
              }
              : item,
          ),
          totalPrice: state.totalPrice + totalItemPrice,
        };
      } else {
        return {
          ...state,
          Items: [...state.Items, action.payload],
          totalPrice: state.totalPrice + totalItemPrice,
        };
      }


    case REMOVE_FROM_CART:
      const removeItem = state.Items.find(
        item =>
          item.id === action.payload.id &&
          item.isPack === action.payload.isPack,
      );
      if (removeItem.qty <= 1) {
        return {
          ...state,
          Items: state.Items.filter(
            item =>
              item.id !== action.payload.id ||
              item.isPack !== action.payload.isPack,
          ),
          totalPrice: state.totalPrice - action.payload.price,
        };
      } else
        return {
          ...state,
          Items: state.Items.map(item =>
            item.id === action.payload.id &&
              item.isPack === action.payload.isPack
              ? {
                ...item,
                qty:
                  item.qty -
                  action.payload.qty,
              }
              : item,
          ),
          totalPrice: state.totalPrice - action.payload.price,
        }

    case CLEAR_CART:
      return (state = { ...initialState });
  }
  return state;
};

export default cartItems;
