import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import { connect } from "react-redux";
import * as actions from "../Redux/Actions/cartAction";

function Cart(props) {
  const navigate = useNavigate();
  const drawerWidth = 350;

  const total = props.cartItems.totalPrice.toFixed(2);
  // const total = 0

  return (
    <Drawer
      anchor="right"
      variant="persistent"
      open={props.open}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
        },
      }}
    >
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={() => props.setOpen(false)}>
          <CloseIcon />
        </Button>
      </div>
      {props.cartItems.Items.length ? (
        <div>
          {props.cartItems.Items.length ? (
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography fontSize={20} fontWeight={"bold"}>
                  Products
                </Typography>
              </div>
              {props.cartItems.Items.map((data) => {
                return (
                  <Card sx={{ m: 2, p: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography fontSize={18}>
                        <b>
                          {data.name.length > 25
                            ? data.name.substring(0, 25 - 3) + "..."
                            : data.name}
                        </b>
                      </Typography>
                      <Chip
                        label={data.isPack ? "Pack" : "Unit"}
                        color={data.isPack ? "secondary" : "primary"}
                        size="small"
                        sx={{ ml: 1 }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography color={"primary"}>
                        <b>Rs. {data.price}</b>
                      </Typography>
                      <Stack
                        direction={"row"}
                        spacing={1}
                        sx={{ alignItems: "center" }}
                      >
                        <IconButton
                          onClick={() => {
                            props.removeFromCart({
                              ...data,
                              qty: 1,
                            });
                          }}
                        >
                          {data.quantity === 1 ? (
                            <DeleteOutlineOutlinedIcon color={"secondary"} />
                          ) : (
                            <RemoveIcon color={"primary"} />
                          )}
                        </IconButton>
                        <Typography>{data.qty}</Typography>
                        <IconButton
                          onClick={() => {
                            props.addItemToCart({
                              ...data,
                              qty: 1,
                            });
                          }}
                        >
                          <AddIcon color={"primary"} />
                        </IconButton>
                      </Stack>
                    </Box>
                  </Card>
                );
              })}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: 20,
                }}
              >
                <Typography>
                  Total: <b>{total}</b>
                </Typography>
              </div>
            </div>
          ) : null}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              marginRight: 20,
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                props.setOpen(false);
                navigate("/Cart");
              }}
            >
              View Cart
            </Button>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ShoppingCartIcon color="primary" fontSize="large" sx={{ m: 2 }} />
          <Typography>Looks like your Cart is Empty</Typography>
        </div>
      )}
    </Drawer>
  );
}

const mapStateToProps = (state) => {
  const { cartItems } = state;
  return {
    cartItems: cartItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearCart: () => dispatch(actions.clearCart()),
    addItemToCart: (data) => dispatch(actions.addToCart(data)),
    removeFromCart: (data) => dispatch(actions.removeFromCart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
