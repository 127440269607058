import { SET_CURRENT_USER } from "../actions/Auth.actions";
import isEmpty from "../../Assets/common/is-empty";

export default function (state, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.data.token),
        token: action.data.token,
        user: action.data.user
      };
    default:
      return state;
  }
}
