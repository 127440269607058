import * as React from 'react';

//Material UI
import { LoadingButton } from '@mui/lab';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';

import { connect } from 'react-redux';
import { Box } from '@mui/material';


function Review(props) {

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        <b>Order summary</b>
      </Typography>
      <List disablePadding>
        {props.cartItems.Items.map((data) => (
          <ListItem key={data.name} sx={{ py: 1, px: 0 }}>
            <ListItemText primary={data.name} secondary={data.qty + (data.isPack ? ' Pack' : ' Unit')} />
            <Typography variant="body2">Rs. {data.price}</Typography>
          </ListItem>
        ))}

        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Sub Total" primaryTypographyProps={{ fontWeight: 'bold ' }} />
          <Typography variant="subtitle1">
            Rs. {props.cartItems.totalPrice.toFixed(2)}
          </Typography>
        </ListItem>

        <>
          <ListItem sx={{ py: 1, px: 0 }}>
            <ListItemText primary="Discount" primaryTypographyProps={{ fontWeight: 'bold ' }} />
            <Typography variant="subtitle1">
              Rs. {props.cartItems.discount ? props.cartItems.discount.toFixed(2) : 0}
            </Typography>
          </ListItem>
          <ListItem sx={{ py: 1, px: 0 }}>
            <ListItemText primary="Net Total" primaryTypographyProps={{ fontWeight: 'bold ' }} />
            <Typography variant="subtitle1">
              Rs. {props.cartItems.discountedPrice ? props.cartItems.discountedPrice.toFixed(2) : props.cartItems.totalPrice.toFixed(2)}
            </Typography>
          </ListItem>
        </>

      </List>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            <b>Shipping</b>
          </Typography>
          <Typography gutterBottom>{props.userProfile.customer_name}</Typography>
          <Typography gutterBottom>{props.userProfile.phone}</Typography>
          <Typography gutterBottom>{props.userProfile.address}</Typography>
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            <b>Payment Method</b>
          </Typography>
          <Grid container>
            <Typography>Cash on Delivery (Shipping Charges will apply)</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} >
        <LoadingButton loading={props.isLoading} variant='contained' onClick={props.generateOrder}>
          Place Order
        </LoadingButton>
      </Box>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  const { cartItems } = state;
  return {
    cartItems: cartItems,
  };
};


export default connect(mapStateToProps)(Review); 