import axios from "axios";
import Branch from "../Components/Branch";
import Close from "@mui/icons-material/Close";
import baseURL from "../Assets/common/baseURL";
import AboutHeader from "../Components/AboutHeader";
import LoaderImage from "../Assets/images/loader.gif";

import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

import {
  Box,
  Link,
  Stack,
  Modal,
  Select,
  useTheme,
  MenuItem,
  Container,
  InputLabel,
  IconButton,
  Breadcrumbs,
  FormControl,
  useMediaQuery,
} from "@mui/material";
import API from "../utils/API";

function Branches() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [open, setOpen] = useState(true);
  const [cities, setCities] = useState([]);
  const [branches, setBranches] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedCity, setSelectedCity] = useState();

  const isSmall = useMediaQuery(theme.breakpoints.down("290"));
  const isMedium = useMediaQuery(theme.breakpoints.down("750"));

  useEffect(() => {
    loadBranches();
  }, []);

  const loadBranches = async () => {
    const res = await API({
      method: "GET",
      url: "branch/",
    });
    const _cities = new Set();
    res.forEach((item) => {
      _cities.add(item.city);
    });
    setCities([..._cities]);
    setBranches(res.filter((item) => item.active));
    setLoading(true);
  };

  console.log(cities)

  const style = {
    p: 0,
    top: "50%",
    left: "50%",
    boxShadow: 24,
    position: "absolute",
    border: "2px solid #000",
    bgcolor: "background.paper",
    transform: "translate(-50%, -50%)",
    width: isMedium ? window.innerWidth - 20 : 400,
  };

  return (
    <div>
      {isLoading ? (
        <div>
          <AboutHeader>Our Branches</AboutHeader>
          <Container sx={{ minHeight: "40vh" }}>
            <Breadcrumbs>
              <Link underline="hover" color="inherit" href="/">
                Home
              </Link>
              <Link underline="hover" color="inherit">
                Our Branches
              </Link>
            </Breadcrumbs>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: isMedium ? "100%" : "50% 50%",
              }}
            >
              {branches
                .filter((item) => item.city === selectedCity)
                .map((item) => (
                  <Branch
                    key={item.id}
                    branch={item}
                    isSmall={isSmall}
                    isMedium={isMedium}
                  />
                ))}
            </div>
          </Container>

          <Modal open={open} onClose={() => navigate("/")}>
            <Box sx={style}>
              <Stack spacing={0}>
                {!isMedium && (
                  <Box display={"flex"} justifyContent={"flex-end"}>
                    <IconButton onClick={() => navigate("/")}>
                      <Close color="primary" />
                    </IconButton>
                  </Box>
                )}

                <Box sx={{ p: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel id="city-select-input">
                      Select Your City
                    </InputLabel>

                    <Select
                      id="city-select"
                      value={selectedCity}
                      label="Select Your City"
                      labelId="city-select-input"
                      onChange={(event) => {
                        setSelectedCity(event.target.value);
                        setOpen(false);
                      }}
                    >
                      {cities.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Stack>
              <Box></Box>
            </Box>
          </Modal>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            minHeight: "30vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={LoaderImage} height={500} alt="Alt value" />
        </div>
      )}
    </div>
  );
}

export default Branches;
