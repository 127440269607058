import React from 'react'
import { Outlet } from 'react-router-dom'
import AboutHeader from '../Components/AboutHeader'

function DoctorsNavigation() {
    return (
        <>
            <AboutHeader>Doctors</AboutHeader>
            <Outlet />
        </>
    )
}

export default DoctorsNavigation