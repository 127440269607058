import axios from "axios";

import baseURL from "../Assets/common/baseURL";

const apiInstance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

const API = async (config) => {
  const {
    instance = apiInstance,
    method,
    url,
    requestConfig,
    requireAuth = false,
  } = config;

  if (requireAuth) {
    const token = localStorage.getItem("auth_data");
    instance.defaults.headers.common["X-Authorization"] = `token ${JSON.parse(
      token
    )}`;
  } else {
    instance.defaults.headers.common["X-Authorization"] =
      "token 774c53eb36f83b8d0f31d167d6709c0c2d28cc65";
  }

  try {
    const res = await instance[method.toLowerCase()](url, requestConfig);
    return res.data;
  } catch (e) {
    throw new Error(e);
  }
};

export default API;
