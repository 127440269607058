import React from 'react'

// Material UI
import { Grid, Stack, Typography } from '@mui/material'
import DoctorCard from '../../Components/Doctors/DoctorCard'

function SelectDoctor({ search, categories, doctors, locations, locationSchedules, timeSlots }) {

    return (
        <Stack spacing={2} sx={{ mt: 2 }} >
            {search ? (
                <Grid container spacing={2} >
                    {doctors.map((doctor) => {
                        if (doctor.category_id === search) {
                            return (
                                <DoctorCard data={doctor} locations={locations} locationSchedules={locationSchedules} timeSlots={timeSlots} />
                            )
                        }
                    })}
                </Grid>
            ) : categories.map((category) => {
                return (
                    <>
                        <Typography variant={'h5'} fontWeight={'bold'} >{category.label}</Typography>
                        <Grid container spacing={2} >
                            {doctors.map((doctor, index) => {
                                if (doctor.category_id === category.id) {
                                    if (index < 5) {
                                        return (
                                            <DoctorCard data={doctor} locations={locations} locationSchedules={locationSchedules} timeSlots={timeSlots} />
                                        )
                                    }
                                }
                            })}
                        </Grid>
                    </>
                )
            })}
        </Stack>
    )
}

export default SelectDoctor