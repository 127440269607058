import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

const Protected = ({ isLoggedIn, children, setLocation }) => {
  const location = useLocation();

  useEffect(() => {
    setLocation(location);
  }, [location, setLocation]);

  if (!isLoggedIn) {
    return <Navigate to="/account/signin" />;
  }
  return children;
};

export default Protected;
