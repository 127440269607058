import React, { useEffect, useState } from "react";
import { useTheme, useMediaQuery } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import axios from "axios";

import baseURL from "../Assets/common/baseURL";

function Banner(props) {
  const banners = props.banners;

  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const [height, setHeight] = useState(350);

  useEffect(() => {
    if (isMd) {
      setHeight(150);
    } else {
      setHeight(350);
    }
  }, [isMd]);

  return (
    <div style={{ display: "block" }}>
      <Carousel
        autoPlay
        autoFocus={true}
        infiniteLoop
        showThumbs={false}
        showIndicators={false}
        dynamicHeight={true}
      >
        {banners.filter((item) => item.image_web).map((item) => {
          const image = item.image_web;
            return (
              <div>
                <img 
                  src={image}
                  alt="NMP Pharmacy"
                  width={"100%"}
                  height={height}
                  style={{ objectFit: "cover", objectPosition: "center" }}
                />
              </div>
            );
        })}
      </Carousel>
    </div>
  );
}

export default Banner;
