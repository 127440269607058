import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

//Material UI
import {
  Button,
  Container,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Box,
} from "@mui/material";

import Address from "./Checkout/Address";
import Payment from "./Checkout/Payment";
import Review from "./Checkout/Review";

//Redux
import { connect } from "react-redux";
import * as actions from "../Redux/Actions/cartAction";

import baseURL from "../Assets/common/baseURL";
import API from "../utils/API";
import AuthGlobal from "../Context/store/AuthGlobal";

const steps = ["Shipping address", "Payment details", "Review your order"];

function Checkout(props) {
  const navigate = useNavigate();

  const context = useContext(AuthGlobal);
  const todayDate = new Date().toISOString().slice(0, 10);

  //Profile Info
  const [user_id, setUser_ID] = useState();
  const [customer_name, setCustomerName] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [address, setAddress] = useState();

  const [total, setTotal] = useState();

  const [couponData, setCouponData] = useState([]);

  const [message, setMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  const [remainderON, setRemainderON] = useState(false);
  const [RemainderType, setRemainderType] = useState("sms");
  const [RemainderDate, setRemainderDate] = useState(todayDate);

  const userProfile = {
    customer_name,
    phone,
    email,
    address,
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <Address
            setUser_ID={setUser_ID}
            setCustomerName={setCustomerName}
            setPhone={setPhone}
            setEmail={setEmail}
            setUserAddress={setAddress}
          />
        );
      case 1:
        return (
          <Payment
            setCouponData={setCouponData}
            remainderON={remainderON}
            RemainderType={RemainderType}
            RemainderDate={RemainderDate}
            setRemainderON={setRemainderON}
            setRemainderType={setRemainderType}
            setRemainderDate={setRemainderDate}
          />
        );
      case 2:
        return (
          <Review
            loading={isLoading}
            userProfile={userProfile}
            generateOrder={generateOrder}
            couponData={couponData}
            setTotal={setTotal}
            total={total}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  }

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  //Place Order Start\

  const total_item = props.cartItems.Items.length;
  const total_buying_amount = total;
  const platform = "web";

  const created_at = new Date().toJSON();

  const generateOrder = async () => {
    setLoading(true);
    try {
      let total_qty = 0;
      let total_price = 0;
      props.cartItems.Items.map((item) => {
        if (item.isPack) {
          const qty = item.qty * item.qty_in_pack;
          total_qty = total_qty + qty;
          total_price = total_price + item.qty * item.price;
        } else {
          total_qty = total_qty + item.qty;
          total_price = total_price + item.qty * item.price;
        }
      });
      const res = await API({
        method: "POST",
        url: "invoice/",
        requreAuth: true,
        requestConfig: {
          discount: props.cartItems.discount,
          customer: context.stateUser.user.id,
          total_amount: props.cartItems.discountedPrice
            ? props.cartItems.discountedPrice.toFixed(2)
            : props.cartItems.totalPrice.toFixed(2),
          sub_total_amount: props.cartItems.totalPrice.toFixed(2),
          invoice_no: "1111",
          channel: "Web",
        }
      });
      console.log(res)
      props.cartItems.Items.map(
        async (item) => {
          const res1 = await API({
            method: "POST",
            url: "item/",
            requreAuth: true,
            requestConfig: {
              invoice_no: res.id,
              product: item.id,
              product_name: item.name,
              quantity: item.qty,
              amount: (item.qty * item.price),
              rate: parseFloat(item.price),
            },
          });
          console.log(res);
        }
      );
      window.alert(`Order Placed Successfully. Your Order ID is ${res.invoice_no}`);  
      props.clearCart();
      navigate("/");
    } catch (e) {
      console.log(e);
      window.alert("Error placing order");
    }
  };

  return (
    <Container component="main" maxWidth="sm" sx={{ mb: 4, minHeight: "40vh" }}>
      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <Typography component="h1" variant="h4" align="center">
          Checkout
        </Typography>
        <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <React.Fragment>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography variant="subtitle1">{message}</Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {getStepContent(activeStep)}
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {activeStep === 1 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Back
                  </Button>
                )}
                {activeStep === steps.length - 1 ? null : (
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    Next
                  </Button>
                )}
              </Box>
            </React.Fragment>
          )}
        </React.Fragment>
      </Paper>
    </Container>
  );
}

const mapStateToProps = (state) => {
  const { cartItems } = state;
  return {
    cartItems: cartItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearCart: () => dispatch(actions.clearCart()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
