import React from 'react'
import { Outlet } from 'react-router-dom'
import { useMediaQuery, useTheme } from '@mui/material'

import SignInImage from '../../Assets/images/SignInPage.jpeg'


function Account() {

  const theme = useTheme()
  const isMatch = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <div style={{ display: 'flex', maxHeight: '100vh'}} >
      {isMatch ? null : <img src={SignInImage} style={{ height: 'auto', width: '60%' }} />}
      <div style={{ backgroundColor: '#f9f9f9', padding: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }} >
        <Outlet />
      </div>
    </div>
  )
}

export default Account