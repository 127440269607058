import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom'
import { Container, Box, Typography } from "@mui/material";
import OtpInput from "react-otp-input";

import baseURL from "../../Assets/common/baseURL";

function ForgotPasswordOTP() {
  const navigate = useNavigate()
  const raw_phone = useLocation().state
  const [OTP, setOTP] = useState("");
  const [input, setInput] = useState();

  const phone = '92' + raw_phone.substring(1);

  const number = {
    phone,
  };

  useEffect(() => {
    send_OTP();
  }, []);

  const send_OTP = () => {
    fetch(`${baseURL}sendOTP`, {
      method: 'POST',
      body: JSON.stringify(number),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(data => setOTP(data.data.OTP));
  };

  const inputNum = parseInt(input)

  if (inputNum === OTP) {
    navigate('../changepassword', { state: raw_phone })
  }

  return (
    <Container component="main" maxWidth="xs" sx={{ display: 'flex', height: '100vh', alignItems: 'center' }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#fff",
          padding: 5,
          borderRadius: 10,
        }}
      >
        <Typography component="h1" variant="h5">
          Enter OTP
        </Typography>
        <Typography >
          OTP has been sent to your phone number. Please enter the 6-digit OTP to proceed.
        </Typography>
        <Box sx={{ mt: 3, mb: 2 }} >
          <OtpInput
            value={input}
            onChange={(otp) => { setInput(otp) }}
            numInputs={6}
            inputStyle={{ height: 35, width: 20, borderRadius: 5, margin: 5, fontSize: 18, color: 'blue' }}
            separator={<span>-</span>}
          />
        </Box>
      </Box>
    </Container>
  );
}

export default ForgotPasswordOTP;
