import React, { useState, useEffect } from 'react'
import './styles.css'

import AboutHeader from '../../Components/AboutHeader'
import OurHistoryImage from '../../Assets/images/Our-History.png'

import baseURL from '../../Assets/common/baseURL'

function OurHistory() {
    const [data, setData] = useState()

    useEffect(() => {
        fetch(`${baseURL}getPages/2`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then(res => res.json())
            .then(data => setData(data.data))
    }, [])

    const rawHTML = `<span>${data ? data.description : ''}<span>`


    return (
        <div>
            <AboutHeader>Our History</AboutHeader>
            <div style={{ backgroundColor: '#a1a1a1', padding: 10}}>
                <img src={OurHistoryImage} width={'100%'} />
            </div >
        </div>
    )
}

export default OurHistory