import axios from "axios";
import baseURL from "../../Assets/common/baseURL";
import SearchIcon from "@mui/icons-material/Search";
import EmptyLogo from "../../Assets/images/Emptylogo.png";
import * as actions from "../../Redux/Actions/cartAction";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { createFilterOptions } from "@mui/material/Autocomplete";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  Autocomplete,
} from "@mui/material";
import API from "../../utils/API";

function Search(props) {
  const navigate = useNavigate();

  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    searchProducts();
  }, [query]);

  const searchProducts = async () => {
    setLoading(true);
    if (query.length >= 3) {
      const res = await API({
        method: "GET",
        url: `product_search?search=${query}`,
      });
      setProducts(res.results);
    } else {
      setProducts([]);
    }
    setLoading(false);
  };

  const handleAdd = (product) => {
    const payloadData = {
      id: product.id,
      name: product.name,
      qty: 1,
      image: product.image,
      prescription_required: product.prescription_required,
      price: product.price_unit,
      isPack: false,
    };
    props.addItemToCart(payloadData);
  };

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterProducts = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  const handleKetDownAction = (e) => {
    if (e.key === "Enter") {
      e.defaultMuiPrevented = true;

      navigate(`/search/${query}`);
      setOpen(false);
      props.setOpen(false);
    }
  };

  const handleOnClose = () => {
    setOpen(false);
    props.setOpen(false);
  };

  const handleProductClick = (option) => {
    navigate("/product", { state: { product: option } });
    setOpen(false);
  };

  console.log(products);

  return (
    <div style={{ display: "flex" }}>
      <Autocomplete
        freeSolo
        fullWidth
        open={open}
        disableClearable
        options={products}
        loading={loading}
        onClose={handleOnClose}
        onKeyDown={handleKetDownAction}
        autoHighlight
        // filterOptions={filterProducts}
        getOptionLabel={(option) =>
          option.name + option.generic_name + option.brand_name
        }
        renderOption={(props, option) => (
          <>
            <Box
              sx={{ "& > img": { mr: 2 } }}
              {...props}
              onClick={() => handleProductClick(option)}
            >
              <img
                src={option.image}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = EmptyLogo;
                }}
                alt={option.name}
                height={50}
                width={50}
                style={{ borderRadius: 25, cursor: "pointer" }}
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Typography
                    sx={{ fontSize: 16, fontWeight: "bold", letterSpacing: 1 }}
                  >
                    {option.name}
                  </Typography>
                  <Typography color={"primary"} sx={{ fontSize: 14 }}>
                    Rs. <b>{option.price_unit} / Unit</b>
                  </Typography>
                </div>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAdd(option);
                  }}
                >
                  <AddShoppingCartIcon color="primary" />
                </IconButton>
              </div>
            </Box>
          </>
        )}
        renderInput={(params) => (
          /*<OutlinedInput
                        {...params}
                        fullWidth
                        placeholder="Search"
                        size="small"
                        onChange={(e) => setQuery(e.target.value)}
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                navigate(`/search/${query}`)
                            }
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                
                            </InputAdornment>
                        }
                    />*/
          <TextField
            {...params}
            fullWidth
            placeholder="Search"
            size="small"
            value={query}
            onFocus={() => setOpen(true)}
            onChange={(e) => setQuery(e.target.value)}
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
      />
      <IconButton
        onClick={() => navigate(`/search/${query}`)}
        edge="end"
        sx={{ ml: 1 }}
      >
        <SearchIcon color="primary" />
      </IconButton>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    addItemToCart: (data) => dispatch(actions.addToCart(data)),
  };
};

export default connect(null, mapDispatchToProps)(Search);
