import React from 'react'

// Material UI
import { Typography, useTheme, useMediaQuery } from '@mui/material'

// Image
import HeaderImage from '../Assets/images/headerimage.jpeg'

function AboutHeader({ children }) {

    const theme = useTheme()

    const isMatch = useMediaQuery(theme.breakpoints.down("md"))

    return (
        <div style={{ padding: isMatch ? 20 : 50, backgroundImage: `url(${HeaderImage})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: isMatch ? '100% 100px    ' : '100% 200px', objectFit: 'cover' }} >
            <Typography fontSize={isMatch ? 18 : 40} color={'#fff'} ><b>{children}</b></Typography>
        </div>
    )
}

export default AboutHeader