import { createStore, combineReducers } from 'redux';
import { loadState, saveState } from './sessionStorage';

import cartItems from './Reducers/cartItems';

const reducers = combineReducers({
    cartItems: cartItems
})
const persistedState = loadState();
const store = createStore(
    reducers,
    persistedState,
)

store.subscribe(() => {
    saveState(store.getState())
})

export default store