import React from "react";

import { Typography, Button } from "@mui/material";

import { VisibilityContext } from "react-horizontal-scrolling-menu";

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export function LeftArrow() {
    const {
        isFirstItemVisible,
        scrollPrev,
        visibleItemsWithoutSeparators,
        initComplete
    } = React.useContext(VisibilityContext);

    const [disabled, setDisabled] = React.useState(
        !initComplete || (initComplete && isFirstItemVisible)
    );
    React.useEffect(() => {
        // NOTE: detect if whole component visible
        if (visibleItemsWithoutSeparators.length) {
            setDisabled(isFirstItemVisible);
        }
    }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

    return (
        <Button disabled={disabled} onClick={() => scrollPrev()}>
            <ArrowBackIosIcon fontSize="small" />
        </Button>
    );
}

export function RightArrow() {
    const {
        isLastItemVisible,
        scrollNext,
        visibleItemsWithoutSeparators
    } = React.useContext(VisibilityContext);

    const [disabled, setDisabled] = React.useState(
        !visibleItemsWithoutSeparators.length && isLastItemVisible
    );
    React.useEffect(() => {
        if (visibleItemsWithoutSeparators.length) {
            setDisabled(isLastItemVisible);
        }
    }, [isLastItemVisible, visibleItemsWithoutSeparators]);

    return (
        <Button disabled={disabled} onClick={() => scrollNext()}>
            <ArrowForwardIosIcon fontSize="small" />
        </Button>
    );
}



const Arrows = (props) => (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6" marginTop={1} marginBottom={1} ><b>{props.heading}</b></Typography>
        <div style={{ display: 'flex' }} >
            <LeftArrow /> <RightArrow />
        </div>
    </div>
);

export default Arrows