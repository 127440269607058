import React, { useState, useEffect } from 'react'
import { Container, Tabs, Tab } from '@mui/material'

//Components
import Dashboard from './Profile/Dashboard'
import ProfileOrders from './Profile/ProfileOrders'
import AccountDetails from './Profile/AccountDetails'
import ChangePassword from './Profile/ChangePassword';
import Logout from './Profile/Logout';

//fetch
import baseURL from '../Assets/common/baseURL';



function Profile(props) {

    const userProfile = props.userProfile

    const [tabValue, setTabValue] = useState(1)

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const [orderData, setOrderData] = useState([])
    const [orderCount, setOrderCount] = useState('')


    const user = {
        user_id: props.userProfile ? props.userProfile.id : ''
    }

    useEffect(() => {
        fetch(`${baseURL}getOrders`, {
            method: "POST",
            body: JSON.stringify(user),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data)
                setOrderData(data.data.orders)
                setOrderCount(data.data.orders_count)
            })
    }, [])



    function TabContent() {
        switch (tabValue) {
            case 1:
                return <Dashboard userProfile={userProfile} />
            case 2:
                return <ProfileOrders userProfile={userProfile} orderDetails={orderData} orderCount={orderCount} />
            case 3:
                return <AccountDetails userProfile={userProfile} />
            case 4:
                return <ChangePassword userProfile={userProfile} />
            case 5:
                return <Logout />
            default:
                return null
        }
    }


    return (
        <Container sx={{ mt: 5, mb: 5 }} >
            <Tabs value={tabValue} onChange={handleChange} variant='scrollable' scrollButtons="auto" >
                <Tab label="Dashboard" value={1} />
                <Tab label="Your Orders" value={2} />
                <Tab label="Account Details" value={3} />
                <Tab label="Change Password" value={4} />
                <Tab label="Logout" value={5} />
            </Tabs>

            {TabContent()}

        </Container>
    )
}

export default Profile