import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import axios from "axios";
import baseURL from "../Assets/common/baseURL";
import TrackOrderCard from "../Components/TrackOrder/TrackOrderCard";

import LoaderImage from "../Assets/images/loader.gif";
import API from "../utils/API";
import AuthGlobal from "../Context/store/AuthGlobal";

function TrackOrder() {
  const context = useContext(AuthGlobal);

  const [orderData, setOrderData] = useState([]);
  const [orderCount, setOrderCount] = useState("");
  const [isLoading, SetLoading] = useState(false);
  const [orderNumber, setOrderNumber] = useState("");

  const handleSearch = async () => {
    const res = await API({
      method: "GET",
      url: "invoice/?invoice_no=" + orderNumber,
      requireAuth: true,
    });
    if (res.length === 0) {
      alert("No Order Found");
      return;
    }
    const res1 = await API({
      method: "GET",
      url: "item/?search=" + res[0].id,
      requireAuth: true,
    });
    res[0].items = res1;
    setOrderData([res[0]]);
    setOrderCount(res.length);
  };

  return (
    <div>
      {!isLoading ? (
        <Container maxWidth={"md"} sx={{ p: 2, minHeight: "45vh" }}>
          <Stack spacing={3}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Typography variant="h3">Track Orders</Typography>
            </div>
            <Stack direction="row" spacing={2}>
              <TextField
                label="Order Number"
                variant="outlined"
                autoComplete="off"
                fullWidth
                style={{ marginTop: "10px" }}
                onChange={(e) => setOrderNumber(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: "10px" }}
                onClick={handleSearch}
              >
                Search
              </Button>
            </Stack>

            <Grid container>
              {[...orderData].reverse().map((order) => {
                return <TrackOrderCard data={order} />;
              })}
            </Grid>
          </Stack>
        </Container>
      ) : (
        <div
          style={{
            minHeight: "30vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={LoaderImage} height={500} />
        </div>
      )}
    </div>
  );
}

export default TrackOrder;
