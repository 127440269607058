import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SortIcon from "@mui/icons-material/Sort";
import ProductCard from "../Components/Products/ProductCard";
import API from "../utils/API";

const ProductsByBrand = () => {
  let { brandID } = useParams();

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [next, setNext] = useState(null);
  const [totalProducts, setTotalProducts] = useState(0);
  const [currentProducts, setCurrentProducts] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [selectedSort, setSelectedSort] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    fetchBrands();
    fetchProducts();
  }, [brandID]);

  const fetchBrands = async () => {
    const res = await API({
      method: "GET",
      url: "brand/",
    });
    setBrands(res.filter((item) => item.web === true));
  };

  const fetchProducts = async () => {
    const res = await API({
      method: "GET",
      url: `product/?brand=${brandID}`,
    });
    setProducts(res.results);
    setTotalProducts(res.count);
    setCurrentProducts(res.results.length);
    setNext(res.next);
  };

  const loadMore = async () => {
    setLoading(true);
    const res = await API({
      method: "GET",
      url: next,
    });
    setProducts([...products, ...res.results]);
    setCurrentProducts(currentProducts + res.results.length);
    setNext(res.next);
    setLoading(false);
  };

  return (
    <Box marginX={5}>
      <Breadcrumbs
        sx={{
          mt: 2,
        }}
      >
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
        <Link underline="hover" color="inherit">
          {brands?.find((brand) => brand.id === parseInt(brandID))?.name}
        </Link>
      </Breadcrumbs>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} lg={2}>
          <Card
            sx={{
              width: "100%",
              mt: 3,
            }}
          >
            <CardContent>
              <Stack spacing={2}>
                <Stack spacing={2}>
                  <Typography variant="h6" fontWeight={"bold"}>
                    Sort
                  </Typography>
                  <Link
                    sx={{
                      color:
                        selectedSort === "Price: Low to High"
                          ? "secondary.main"
                          : "primary.main",
                      cursor: "pointer",
                    }}
                    fontSize={14}
                    onClick={() => {
                      setSelectedSort("Price: Low to High");
                      setAnchorEl(null);
                      setProducts(
                        [...products].sort(
                          (a, b) =>
                            parseFloat(a.price_unit) - parseFloat(b.price_unit)
                        )
                      );
                    }}
                  >
                    Low to High
                  </Link>
                  <Link
                    sx={{
                      color:
                        selectedSort === "Price: High to Low"
                          ? "secondary.main"
                          : "primary.main",
                      cursor: "pointer",
                    }}
                    fontSize={14}
                    onClick={() => {
                      setSelectedSort("Price: High to Low");
                      setAnchorEl(null);
                      setProducts(
                        [...products].sort(
                          (a, b) =>
                            parseFloat(b.price_unit) - parseFloat(a.price_unit)
                        )
                      );
                    }}
                  >
                    High to Low
                  </Link>
                </Stack>
                <Stack spacing={2}>
                  <Typography variant="h6" fontWeight={"bold"}>
                    Brands
                  </Typography>
                  {brands.map((brand) => (
                    <Link
                      key={brand.id}
                      fontSize={14}
                      sx={{
                        display: "block",
                        mt: 2,
                        cursor: "pointer",
                        color:
                          brand.id === parseInt(brandID)
                            ? "secondary.main"
                            : "primary.main",
                      }}
                      onClick={() => navigate(`/brand/${brand.id}`)}
                    >
                      <Typography variant="p">{brand.name} </Typography>
                    </Link>
                  ))}
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={9} lg={10}>
          <Grid
            container
            sx={{
              mt: 2,
              mb: 5,
            }}
          >
            {products.map((product) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
                <ProductCard product={product} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Box
            component={"img"}
            src={require("../Assets/images/loader.gif")}
            height={100}
            width={300}
            style={{
              objectFit: "cover",
            }}
            sx={{
              mt: 2,
              mb: 5,
            }}
            alt={"loading"}
          />
        </Box>
      )}

      {next && !isLoading && (
        <Stack
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Typography>
            You've viewed {currentProducts} of {totalProducts}
          </Typography>

          <Button
            variant="contained"
            color="primary"
            sx={{ height: 50, mt: 2, mb: 5 }}
            onClick={loadMore}
          >
            Load More Products
          </Button>
        </Stack>
      )}
    </Box>
  );
};

export default ProductsByBrand;
