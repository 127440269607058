import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

//Material UI
import { Box, Container, Grid } from '@mui/material'

//Components
import TestCard from '../../Components/LabTests/TestCard'

//Fetch
import baseURL from '../../Assets/common/baseURL'
import axios from 'axios'
import AboutHeader from '../../Components/AboutHeader'

function Tests() {

  let { LabID } = useParams()

  const [tests, setTests] = useState([])

  useEffect(() => {
    getTests()
  }, [])


  const getTests = async () => {
    const testData = await axios
      .get(`${baseURL}getTests/${LabID}`)
      .then((res) => setTests(res.data.data.tests))
  }


  return (
    <>
      <AboutHeader>Tests</AboutHeader>
      <Container sx={{ minHeight: '40vh', mt: 3, mb: 3 }}>
        <Grid container spacing={3} >
          {tests.map((item) => {
            return <TestCard data={item} />
          })}
        </Grid>
      </Container>
    </>
  )
}

export default Tests
