import React, { useContext, useState } from "react";

//Material UI
import {
  Alert,
  Box,
  Button,
  Collapse,
  Container,
  CssBaseline,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

//Fetch
import baseURL from "../../Assets/common/baseURL";
import API from "../../utils/API";
import { LoadingButton } from "@mui/lab";
import AuthGlobal from "../../Context/store/AuthGlobal";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";

function ChangePassword(props) {
  const context = useContext(AuthGlobal);
  const phone = context.stateUser?.user?.phone;

  const [password, setPassword] = useState();
  const [confirmpassword, setConfirmPassword] = useState();
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const user = {
    phone,
    password,
    confirmpassword,
  };

  const changePassword = async () => {
    setLoading(true);
    try {
      if (password === confirmpassword) {
        const res = await API({
          method: "POST",
          url: "setpassword/",
          requestConfig: {
            username: user.phone,
            new_password: user.password,
            confirm_password: user.confirmpassword,
          },
        });
        setPasswordChanged(true);
      } else {
        setPasswordMismatch(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{ display: "flex", alignItems: "center" }}
    >
      <CssBaseline />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 4,
          borderRadius: 5,
          textAlign: "left",
        }}
      >
        <Typography component="h3" variant="h4">
          Change Password
        </Typography>
        <Stack spacing={2} sx={{ mt: 3 }}>
          <Box
            sx={{
              position: "relative",
            }}
          >
            <TextField
              label="New Password"
              fullWidth
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              sx={{ position: "absolute", right: 5, bottom: 8 }}
            >
              {showPassword ? (
                <VisibilityOutlined />
              ) : (
                <VisibilityOffOutlined />
              )}
            </IconButton>
          </Box>
          <Box
            sx={{
              position: "relative",
            }}
          >
            <TextField
              label="Confirm Password"
              fullWidth
              type={showPassword ? "text" : "password"}
              value={confirmpassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              sx={{ position: "absolute", right: 5, bottom: 8 }}
            >
              {showPassword ? (
                <VisibilityOutlined />
              ) : (
                <VisibilityOffOutlined />
              )}
            </IconButton>
          </Box>
          <Collapse in={passwordMismatch}>
            <Alert onClose={() => setPasswordMismatch(false)} severity="error">
              Password Doesn't Match
            </Alert>
          </Collapse>
          <Collapse in={passwordChanged}>
            <Alert onClose={() => setPasswordChanged(false)} severity="success">
              Password Changed
            </Alert>
          </Collapse>
          <LoadingButton
            variant="contained"
            onClick={changePassword}
            loading={loading}
          >
            Confirm
          </LoadingButton>
        </Stack>
      </Box>
    </Container>
  );
}

export default ChangePassword;
