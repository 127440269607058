import { Card, CardActionArea } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

const BrandCard = ({ data, primaryColor }) => {

  const navigate = useNavigate();

  return (
    <Card
      sx={{
        width: 150,
        height: 150,
        margin: 2,
        borderRadius: 6,
        boxShadow: "none",
        transition: "0.3s",
        "&:hover": {
          transform: "scale(1.05)",
          boxShadow: "0 4px 20px 0 rgba(0,0,0,0.12)",
        },
      }}
    >
      <CardActionArea
        sx={{
          padding: 2,
        }}
        onClick={() => navigate(`/brand/${data.id}`)}
      >
        <img
          src={data.image}
          alt={data.name}
          style={{ width: "100%", height: "auto" }}
        />
      </CardActionArea>
    </Card>
  );
};

export default BrandCard;
