import React, { useState, useEffect } from 'react'

// Materail UI
import {
    Autocomplete,
    TextField,
    Container,
    Stack,
    Button
} from '@mui/material'

// API
import axios from 'axios'
import baseURL from '../../Assets/common/baseURL'

import LoaderImage from '../../Assets/images/loader.gif'
import SelectDoctor from './SelectDoctor'


function Doctors() {

    const [categories, setCategories] = useState([])
    const [doctors, setDoctors] = useState([])
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState()
    const [locations, setLocations] = useState([])
    const [locationSchedules, setLocationSchedules] = useState([])
    const [timeSlots, setTimeSlots] = useState([])

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        const loadCategories = await axios
            .get(`${baseURL}doctorCategories`)
            .then(res => setCategories(res.data.data.doctor_categories))
        const loadDoctors = await axios
            .get(`${baseURL}getDoctors`)
            .then(res => setDoctors(res.data.data.doctors))
        const locationdata = await axios
            .get(`${baseURL}getDoctorLocations`)
            .then((res) => setLocations(res.data.data.locations))
        const timeSlotsdata = await axios
            .get(`${baseURL}getTimeSlots`)
            .then((res) => setTimeSlots(res.data.data.time_slots))
        const locationSchedulesdata = await axios
            .get(`${baseURL}getLocationSchedules`)
            .then((res) => setLocationSchedules(res.data.data.location_schedule))
        setLoading(true)
    }

    return (
        <>
            {loading ? (
                <Container sx={{ mt: 3, mb: 3 }}>
                    <Autocomplete
                        id="specialities"
                        disableClearable
                        fullWidth
                        options={categories.map((option) => option)}
                        onChange={(event, newValue) => {
                            setSearch(newValue.id)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Search Speciality"
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                }}
                            />
                        )}
                    />
                    <SelectDoctor
                        search={search}
                        categories={categories}
                        doctors={doctors}
                        locations={locations}
                        locationSchedules={locationSchedules}
                        timeSlots={timeSlots}
                    />
                </Container>
            ) : (
                <div style={{ minHeight: '30vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                    <img src={LoaderImage} height={500} />
                </div>
            )}
        </>
    )
}

export default Doctors