import Cart from "./Cart";
import Footer from "./Footer";
import Header from "./Header/Header";
import StickyHeader from "./Header/StickyHeader";
import TimerIcon from "@mui/icons-material/Timer";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import React, { useState } from "react";
import { Fab, Box, Tooltip } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";

function App(props) {
  const navigate = useNavigate();

  const style = {
    zIndex: 100,
    right: "26px",
    bottom: "26px",
    display: "flex",
    position: "fixed",
    flexDirection: "column",
  };

  const [open, setOpen] = useState(false);

  return (
    <div>
      <Header
        authStatus={props.authStatus}
        userName={props.userProfile ? props.userProfile.name : ""}
      />
      <StickyHeader setOpen={setOpen} />
      <Cart open={open} setOpen={setOpen} />
      <Outlet />

      <Box sx={style}>
        <Tooltip title={"Track Order"} placement={"left"} arrow>
          <Fab
            color="secondary"
            sx={{ margin: 1 }}
            size={"medium"}
            onClick={() => navigate("/track-order")}
          >
            <LocationOnIcon />
          </Fab>
        </Tooltip>
        <Tooltip title={"Fast Order"} placement={"left"} arrow>
          <Fab
            color="primary"
            sx={{ margin: 1 }}
            size={"medium"}
            onClick={() => navigate("/fastorder")}
          >
            <TimerIcon />
          </Fab>
        </Tooltip>
        <Tooltip title={"Whatsapp"} placement={"left"}>
          <Fab
            color="Whatsapp"
            size={"medium"}
            sx={{ margin: 1 }}
            target="_blank"
            href={"https://wa.link/appyga"}
          >
            <WhatsAppIcon />
          </Fab>
        </Tooltip>
      </Box>

      <Footer />
    </div>
  );
}

export default App;
