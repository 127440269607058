import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import Arrows from '../../Components/Arrows';

// Material UI
import {
    Alert,
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    Stack,
    Switch,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from '@mui/material'

// API
import baseURL from '../../Assets/common/baseURL';
import { AddBox } from '@mui/icons-material';

function DoctorAppointment(props) {

    const [appointment, setAppointment] = useState(false)
    const [success, setSuccess] = useState(false)
    const [locationValue, setLocationValue] = useState(0)

    const userID = props.userProfile.id

    const data = useLocation().state.data
    const locations = useLocation().state.locations
    const locationSchedules = useLocation().state.locationSchedules
    const timeSlots = useLocation().state.timeSlots

    const bookAppointment = () => {
        fetch(`${baseURL}bookAppointment`, {
            method: 'POST',
            body: JSON.stringify({
                doctorID: data.id,
                locationID: locationValue.id,
                locationSlotID: appointment.timeSlot.id,
                userID,
            })
        })
            .then((res) => setSuccess(true))
    }

    return (
        <Container sx={{ mt: 3, mb: 3, minHeight: '40vh' }} >
            <Grid container spacing={3} >
                <Grid item xs={12} md={4} >
                    <Card>
                        <CardContent>
                            <Typography fontSize={24} fontWeight={'bold'} >{data.name}</Typography>
                            <Typography fontSize={16} >{data.qualification}</Typography>
                            <Typography>{data.phone}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Divider />
                <Grid item xs={12} md={8} >
                    <Stack spacing={3} >
                        <Typography fontSize={20} fontWeight={'bold'} >Select Your Location</Typography>

                        <ToggleButtonGroup
                            color='primary'
                            value={locationValue}
                            exclusive
                            onChange={(event, newVal) => setLocationValue(newVal)}
                        >
                            {locations.map((item) => {
                                if (item.doctor_id === data.id) {
                                    return <ToggleButton value={item} >{item.location}</ToggleButton>
                                }
                            })}
                        </ToggleButtonGroup>
                        <Box display={'flex'} justifyContent={'space-between'} >
                            <Typography>Online Appointment</Typography>
                            <Switch />
                        </Box>
                        {locationValue !== 0 ? (
                            <>
                                <Typography fontSize={20} fontWeight={'bold'} >Select Your Time Slot</Typography>
                                <ScrollMenu Header={() => Arrows(props)} >
                                    {locationSchedules.map((item) => {
                                        if (item.location_id === locationValue.id) {
                                            return (
                                                timeSlots.map((timeSlotItem) => {
                                                    if (item.id === timeSlotItem.location_schedule_id) {
                                                        return (
                                                            <Card sx={{ m: 1 }} >
                                                                <CardActionArea onClick={() => setAppointment({
                                                                    location: item,
                                                                    timeSlot: timeSlotItem
                                                                })} >
                                                                    <CardContent>
                                                                        <Typography>{timeSlotItem.value}</Typography>
                                                                    </CardContent>
                                                                </CardActionArea>
                                                            </Card>
                                                        )
                                                    }
                                                })
                                            )
                                        }
                                    })}
                                </ScrollMenu>
                            </>
                        ) : null}
                    </Stack>
                </Grid>
            </Grid>

            <Dialog open={Boolean(appointment)} onClose={() => setAppointment()} >
                <DialogTitle>Book Appointment</DialogTitle>
                <DialogContent>
                    <DialogContentText>Confirm the details below to Book the Appointment</DialogContentText>
                    <DialogContentText>Doctor: {data.name}</DialogContentText>
                    <DialogContentText>Location: {locationValue.location}</DialogContentText>
                    <DialogContentText>Time: {appointment?.timeSlot?.value}</DialogContentText>
                </DialogContent>
                {success ? (
                    <Alert severity='success' >Appointment Booked</Alert>
                ) : (
                    <DialogActions>
                        <Button onClick={() => setAppointment()} >Cancel</Button>
                        <Button variant={'contained'} onClick={bookAppointment} >Confirm</Button>
                    </DialogActions>
                )}
            </Dialog>

        </Container>
    )
}

export default DoctorAppointment