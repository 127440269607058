import React, { useContext, useEffect, useState } from "react";

//Material UI
import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  IconButton,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import API from "../../utils/API";

import AuthGlobal from "../../Context/store/AuthGlobal";

function ProfileOrders() {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const context = useContext(AuthGlobal);

  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [orderCount, setOrderCount] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState(null);

  useEffect(() => {
    loadOrders();
  }, []);

  const loadOrders = async () => {
    setOrderData([]);
    try {
      const res = await API({
        method: "GET",
        url: "invoice/?customer=" + context.stateUser.user.user,
      });
      await res.map(async (item, index) => {
        const res1 = await API({
          method: "GET",
          url: "item/?search=" + item.id,
        });
        item.items = res1;
        item.product_count = res1.length;
        setOrderData((prev) => [...prev, item]);
      });
      setOrderCount(res.length);
    } catch (e) {
      console.log(e);
    }
  };

  const handleCancelOrder = async (id) => {
    try {
      const res = await API({
        method: "PATCH",
        url: `invoice/${selectedOrder.id}/`,
        requireAuth: true,
        requestConfig: {
          delivery_status: "Cancelled",
        },
      });
      if (res) {
        setOpen(false);
        loadOrders();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMatch ? 300 : 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 2,
  };

  return (
    <Container sx={{ mt: 2, mb: 2, minHeight: "30vh" }}>
      <Typography>Total Orders: {orderCount}</Typography>
      <div style={{ overflowY: "scroll" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Order #</b>
              </TableCell>
              <TableCell>
                <b>Date</b>
              </TableCell>
              <TableCell>
                <b>Total Amount</b>
              </TableCell>
              <TableCell>
                <b>Items</b>
              </TableCell>
              <TableCell>
                <b>Status</b>
              </TableCell>
              <TableCell>
                <b>Details</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderData.map((item) => {
              return (
                <TableRow>
                  <TableCell>{item.invoice_no}</TableCell>
                  <TableCell>{item.invoice_date}</TableCell>
                  <TableCell>{item.total_amount}</TableCell>
                  <TableCell>{item.product_count}</TableCell>
                  <TableCell>
                    {item.delivery_status !== null && (
                      <Chip size="small" label={item.delivery_status} />
                    )}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setSelectedOrder(item);
                        setOpen(true);
                        setModalData(item.items);
                      }}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Modal open={open} onClose={() => setOpen(false)}>
          <Box sx={style}>
            <Stack spacing={2}>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography fontSize={22} fontWeight={"bold"}>
                  Order Details
                </Typography>
                <IconButton onClick={() => setOpen(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Stack spacing={2}>
                {modalData.map((item) => {
                  return (
                    <div>
                      <Stack direction={"row"} justifyContent={"space-between"}>
                        <Typography>
                          <b>Product Name: </b>
                        </Typography>
                        <Typography>{item.product_name}</Typography>
                      </Stack>
                      <Stack direction={"row"} justifyContent={"space-between"}>
                        <Typography>
                          <b>Quantity:</b>
                        </Typography>
                        <Typography>{item.quantity}</Typography>
                      </Stack>
                      <Stack direction={"row"} justifyContent={"space-between"}>
                        <Typography>
                          <b>Amount:</b>
                        </Typography>
                        <Typography>{item.amount}</Typography>
                      </Stack>
                      <Divider />
                    </div>
                  );
                })}
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography>
                  <b>Subtotal:</b>
                </Typography>
                <Typography>{selectedOrder?.sub_total_amount}</Typography>
              </Stack>
              <Divider />
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography>
                  <b>Discount:</b>
                </Typography>
                <Typography>{selectedOrder?.discount_amount}</Typography>
              </Stack>
              <Divider />
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography>
                  <b>Total Amount:</b>
                </Typography>
                <Typography>{selectedOrder?.total_amount}</Typography>
              </Stack>
            </Stack>
            <Stack
              direction={"row"}
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 2,
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Close
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  handleCancelOrder(selectedOrder.id);
                }}
              >
                Cancel Order
              </Button>
            </Stack>
          </Box>
        </Modal>
      </div>
    </Container>
  );
}

export default ProfileOrders;
