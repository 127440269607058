import axios from "axios";
import API from "../../utils/API";

export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const loginUser = async (token, dispatch) => {
  localStorage.setItem("auth_data", JSON.stringify(token));
  const res = await API({
    method: "GET",
    url: "customer/",
    requireAuth: true,
  });
  dispatch(setCurrentUser(token, res[0]));
};

export const logoutUser = (dispatch) => {
  localStorage.removeItem("auth_data");
  dispatch(setCurrentUser({}));
};

export const setCurrentUser = (token, user) => {
  return {
    type: SET_CURRENT_USER,
    data: {
      token,
      user: user,
    },
  };
};
