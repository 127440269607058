import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, Typography, Box, Card, CardContent, Grid, Button, Divider, Modal, Stack } from '@mui/material'

import { connect } from 'react-redux';
import * as actions from '../Redux/Actions/cartAction';
import CartCard from '../Components/Cart/CartCard';


function CartPage(props) {

    const navigate = useNavigate()

    const total = props.cartItems.totalPrice.toFixed(2);

    const [prescriptionReq, setPrescriptionReq] = useState(false)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        props.cartItems.Items.map((item) => {
            if (item.prescription_required === true) {
                setPrescriptionReq(true)
            }
        })
    }, [])

    const handleProceed = () => {
        if (prescriptionReq === true) {
            setOpen(true)
        }
        if (prescriptionReq === false) {
            navigate('/checkout')
        }
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Container sx={{ minHeight: '80vh', p: 2 }}>
            <Typography variant='h5'><b>Your Cart</b></Typography>
            <Grid container spacing={3} >
                <Grid item xs={12} md={8}>
                    <Box >
                        {props.cartItems.Items.map((data) => {
                            return <CartCard data={data} />
                        })}
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card sx={{ mt: 2, mb: 5 }} >
                        <CardContent>
                            <Typography variant='h5' marginBottom={2} ><b>Order Summary</b></Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography>Sub Total:</Typography>
                                <Typography><b>Rs. {total}</b></Typography>
                            </Box>
                            <Divider sx={{ mt: 2, mb: 2 }} />
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography>Shipping Charges:</Typography>
                                <Typography><b>Rs. 0</b></Typography>
                            </Box>
                            <Divider sx={{ mt: 2, mb: 2 }} />
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography>Total:</Typography>
                                <Typography><b>Rs. {total}</b></Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant='outlined' onClick={() => navigate('/')} sx={{ marginRight: 2 }} >Continue Shopping</Button>
                <Button variant='contained' onClick={handleProceed} >Proceed to Checkout</Button>
            </Box>
            <Modal open={open} >
                <Box sx={style}>
                    <Typography variant="h6" color={"secondary"} >
                        Prescription Required*
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                        Prescription is Required for one or more Medicines in your Cart
                    </Typography>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }} >
                        <Button variant='contained' onClick={() => navigate('/checkout')} >Proceed</Button>
                    </div>
                </Box>
            </Modal>
        </Container>
    )
}


const mapStateToProps = state => {
    const { cartItems } = state;
    return {
        cartItems: cartItems,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        clearCart: () => dispatch(actions.clearCart()),
        removeFromCart: item => dispatch(actions.removeFromCart(item)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(CartPage)