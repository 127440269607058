import React from "react";

import ProductCard from "./ProductCard";

function ProductList(props) {
  const item = props.product;

  return (
    <ProductCard product={item} responsive={false} />
  );
}

export default ProductList;
