import { Box, CircularProgress, Container } from "@mui/material";
import React, { useState, useEffect } from "react";
import baseURL from "../../Assets/common/baseURL";
import AboutHeader from "../../Components/AboutHeader";
import API from "../../utils/API";

function PrivacyPolicy() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const res = await API({
      method: "GET",
      url: "page/1",
    });
    setData(res);
    setLoading(false);
  };

  const rawHTML = `<span>${data ? data.description : ""}<span>`;

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        height="50vh"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      <AboutHeader>Privacy Policy</AboutHeader>
      <Container>
        <div dangerouslySetInnerHTML={{ __html: rawHTML }}></div>
      </Container>
    </div>
  );
}

export default PrivacyPolicy;
