import React from 'react'
import { useNavigate } from 'react-router-dom'

//Material UI
import { Card, CardActionArea, CardContent, Typography } from '@mui/material'

function LabCard(props) {

    const navigate = useNavigate()

    return (
        <Card sx={{ m: 1 }} >
            <CardActionArea sx={{ height: 100, width: 200 }} onClick={() => navigate(`./tests/${props.data.id}`)} >
                <CardContent sx={{ display: 'flex', justifyContent: 'center', alignItems:'center' }} >
                    <Typography fontSize={22} fontWeight={'bold'} >{props.data.label}</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default LabCard