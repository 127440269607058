import React, { useContext } from 'react'
import { Avatar, Card, CardContent, Container, Typography } from '@mui/material'
import AuthGlobal from '../../Context/store/AuthGlobal';

function Dashboard() {

  const context = useContext(AuthGlobal);

  return (
    <Container sx={{ mt: 2, mb: 2 }}>
      <Card sx={{ m:2 }}>
        <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar sx={{ height: 56, width: 56, m: 2 }} />
          <Typography variant='h5' color={'primary'} sx={{ fontSize: 16 }} ><b>{context.stateUser?.user?.name}</b></Typography>
        </CardContent>
      </Card>
      <Card sx={{ m:2 }}>
        <CardContent>
          <Typography variant='h5' >Details</Typography>
          <Typography marginTop={2} sx={{ fontSize: 14 }} ><b>Phone:</b></Typography>
          <Typography sx={{ fontSize: 14 }} >{context.stateUser?.user?.phone}</Typography>
          <Typography marginTop={2} sx={{ fontSize: 14 }} ><b>Email:</b></Typography>
          <Typography sx={{ fontSize: 14 }} >{context.stateUser?.user?.email}</Typography>
          <Typography marginTop={2} sx={{ fontSize: 14 }} ><b>Address:</b></Typography>
          <Typography sx={{ fontSize: 14 }} >{context.stateUser?.user?.address}</Typography>
        </CardContent>
      </Card>
    </Container>
  )
}

export default Dashboard