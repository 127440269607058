import React, { useEffect, useState } from "react";

//Material UI
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Switch,
} from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import baseURL from "../../Assets/common/baseURL";
import API from "../../utils/API";
import { connect } from "react-redux";

function Payment({ cartItems }) {
  const [couponCode, setCouponCode] = useState();

  const [discount, setDiscount] = useState(0);
  const [coupons, setCoupons] = useState([]);
  const [couponApplied, setCouponApplied] = useState(false);

  const [date, setDate] = useState();

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    loadCoupon();
  }, []);

  const loadCoupon = async () => {
    try {
      const res = await API({
        method: "GET",
        url: `coupon/`,
        requreAuth: true,
      });
      setCoupons(res);
    } catch (e) {
      console.log(e);
    }
  };

  const applyCoupon = async () => {
    if (!couponCode) {
      window.alert("Please enter a coupon code");
      return;
    }
    const coupon = coupons.filter((coupon) => coupon.coupon === couponCode);
    if (coupon.length === 0) {
      window.alert("Coupon not found");
      return;
    }
    coupons.map((coupon) => {
      if (coupon.coupon === couponCode) {
        if (coupon.active === false) {
          window.alert("Coupon is not found or active");
          return;
        }
        window.alert("Coupon Applied");
        if (coupon.type === "Percentage") {
          cartItems.discountedPrice =
            parseFloat(cartItems.totalPrice) -
            parseFloat(cartItems.totalPrice) *
              (parseFloat(coupon.amount) / 100);
          setDiscount(cartItems.totalPrice * (parseFloat(coupon.amount) / 100));
          cartItems.discount = cartItems.totalPrice * (parseFloat(coupon.amount) / 100);
        }
        if (coupon.type === "Flat") {
          cartItems.discountedPrice =
            parseFloat(cartItems.totalPrice) - parseFloat(coupon.amount);
          setDiscount(parseFloat(coupon.amount));
          cartItems.discount = parseFloat(coupon.amount);
        }
        setCouponApplied(true);
      }
    });
  };

  return (
    <>
      <Typography variant="h6" gutterBottom>
        <b>Payment method</b>
      </Typography>
      <Grid container spacing={3} display={"none"}>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="cardName"
            label="Name on card"
            fullWidth
            autoComplete="cc-name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="cardNumber"
            label="Card number"
            fullWidth
            autoComplete="cc-number"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="expDate"
            label="Expiry date"
            fullWidth
            autoComplete="cc-exp"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="cvv"
            label="CVV"
            helperText="Last three digits on signature strip"
            fullWidth
            autoComplete="cc-csc"
            variant="standard"
          />
        </Grid>
      </Grid>
      <Stack spacing={3}>
        <FormControlLabel
          label="Cash on Delivery"
          control={<Checkbox checked={true} />}
        />
        <Stack direction={"row"} spacing={2} sx={{ mt: 5 }}>
          <TextField
            disabled={couponApplied}
            label={"Coupon Code"}
            value={couponCode}
            onChange={(e) => {
              setCouponCode(e.target.value);
            }}
          />
          <LoadingButton
            variant="contained"
            color={couponApplied ? "success" : "primary"}
            loading={isLoading}
            onClick={applyCoupon}
          >
            {couponApplied ? "Applied" : "Apply"}
          </LoadingButton>
        </Stack>
        {/*
        <Box component={'div'} display='flex' justifyContent={'space-between'} >
          <Typography variant='h6' >Reminder</Typography>
          <Switch checked={props.remainderON} onChange={(event) => props.setRemainderON(event.target.checked)} />
        </Box>
        {props.remainderON ? (
          <Stack spacing={2}  >
            <FormControl>
              <RadioGroup
                value={props.RemainderType}
                onChange={(event) => props.setRemainderType(event.target.value)}
              >
                <FormControlLabel value="sms" control={<Radio />} label="SMS" />
              </RadioGroup>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Select Date"
                value={date}
                onChange={(newValue) => {
                  setDate(newValue)
                  props.setRemainderDate(newValue.format("YYYY-MM-DD"))
                }}
                inputFormat="YYYY-MM-DD"
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Stack>
        ) : null}
        */}
      </Stack>
    </>
  );
}

const mapStateToProps = (state) => {
  const { cartItems } = state;
  return {
    cartItems: cartItems,
  };
};

export default connect(mapStateToProps)(Payment);
