import { Box, Container, Typography } from '@mui/material'
import React from 'react'

function ComingSoon() {
    return (
        <Container sx={{  p: 2 }}>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} minHeight={'80vh'} >
                <Typography fontSize={40} fontWeight={'bold'} >Coming Soon...</Typography>
            </Box>
        </Container>
    )
}

export default ComingSoon