import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import { Link } from "@mui/material";
import { Box, Card, CardContent, Typography } from "@mui/material";

function Branch({ branch, isMedium, isSmall }) {
  const image = branch.image;

  return (
    <Card sx={{ m: isMedium ? 1 : 2 }}>
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: isSmall ? "column" : "row",
        }}
      >
        <div>
          <Typography variant="h5">
            <b>{branch.name}</b>
          </Typography>
          <Box
            width={140}
            src={image}
            height={100}
            component={"img"}
            sx={{ objectFit: "cover", borderRadius: 5 }}
          />
          <Typography color="primary">
            <b>Address:</b>
          </Typography>
          <Typography>{branch.address}</Typography>
        </div>
        <div>
          <Typography color={"primary"}>
            <b>Phone:</b>
          </Typography>
          <Typography marginBottom={1}>{branch.phone_number}</Typography>
          <Typography color={"primary"}>
            <b>Whatsapp:</b>
          </Typography>
          <Typography marginBottom={1}>{branch.whatsapp}</Typography>
          <Typography color={"primary"}>
            <b>Email:</b>
          </Typography>
          <Typography marginBottom={1}>{branch.email}</Typography>
          <Box>
            <Link href={branch.location} underline={"hover"} target="_blank">
              <LocationOnIcon color={"secondary"} /> Get Location
            </Link>
          </Box>
        </div>
      </CardContent>
    </Card>
  );
}

export default Branch;
