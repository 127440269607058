import React from 'react'

//Redux
import { connect } from 'react-redux'
import * as actions from '../../Redux/Actions/cartAction'

//Material UI
import { Box, Card, CardContent, Grid, IconButton, Typography } from '@mui/material'
import Add from '@mui/icons-material/Add'

function TestCard(props) {

    return (
        <>
            <Grid item xs={12} md={4} >
                <Card>
                    <CardContent>
                        <Typography fontSize={22} fontWeight={'bold'} >{props.data.label}</Typography>
                        <Typography>{props.data.description}</Typography>

                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} >
                            <Typography>Fee: Rs. <b>{props.data.test_fee}</b></Typography>
                            <IconButton onClick={() => props.addTest(props.data)} >
                                <Add color={'primary'} />
                            </IconButton>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        </>
    )
}



export default TestCard